
import { ImageSwiper } from "@/Components/ImageSwiper";
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { ucwords } from "@/Hooks/Functions";
import LikeUnlike from "@/Modules/Favorite";
import { Icon } from "@iconify/react";
import { Link } from "@inertiajs/react";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from "react";


const EachPredesignProduct = React.memo(({ item }) => {
    const previews = useMemo(() => item?.previews?.map(i => i.url_thumb) || [], [item?.previews]);
    return (
        <Card className="group bg-trasparent cursor-pointer border-none shadow-none relative flex flex-col overflow-hidden">
            <CardContent className="relative shadow-md rounded-md w-full">
                <ImageSwiper images={previews} imageClassName="group-hover:scale-105 duration-300" className="w-full min-h-[300px] rounded-md overflow-hidden" />
                <Link className="w-full absolute bottom-0 z-[50] group-hover:visible invisible" href={route('predesign-products.view', { uuid: item.uuid })}>
                    <Button variant="theme" className="w-full rounded-t-none" size="lg">Select Option</Button>
                </Link>
            </CardContent>
            <CardContent className="w-full flex px-2 py-4 gap-3 relative flex-col">
                <Link href={route('predesign-products.view', { uuid: item.uuid })}>
                    <p className="hover:underline font-semibold text-center md:text-lg text-sm ">{ucwords(item.name)}</p>
                </Link>
                <small className="text-center w-full text-sm text-gray-500 whitespace-nowrap">{item.range_price}</small>
                <LikeUnlike item={item} type="products" />
            </CardContent>
        </Card>
    )
})

const fetchProducts = async () => {
    const response = await window.axios.get(route("predesign-products.paginate"), {
        params: {
            system: 1,
            per_page: 5,
            order: "random:asc",
            withProduct: 1,
            page: 1,
        }
    });
    return response.data;
};
export default function BuyFinishProductBlock() {

    const { data, isLoading, error } = useQuery({
        queryKey: ['products'],
        queryFn: fetchProducts,
        keepPreviousData: true,
    });

    const predesignProducts = data?.data;

    return (
        <div className="w-full flex bg-muted-foreground/10 flex-col justify-center items-center py-4">
            <div className="w-full max-w-7xl flex flex-col pt-12 pb-6">
                <div className="flex flex-row justify-between md:py-6 py-2 md:px-0 px-4 items-center w-full">
                    <h1 data-aos="fade-down" className="md:text-3xl text-lg mb-4 text-center font-semibold font-nunito italic text-primary uppercase">
                        Buy Predesign Products
                    </h1>
                    <Link href={route("home.predesign-products")}>
                        <Button variant="theme" size="sm">
                            <span className="md:inline-flex hidden">View More Product</span>
                            <Icon icon="solar:double-alt-arrow-right-broken" width="30" height="30" />
                        </Button>
                    </Link>
                </div>
                <div className="grid lg:grid-cols-5 md:grid-cols-4 md:p-0 p-4 sm:grid-cols-2 items-stretch grid-cols-1 gap-2">
                    {predesignProducts?.map((predesignProduct, index) => <div key={index} className="!col-span-1">
                        <EachPredesignProduct item={predesignProduct} />
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}
